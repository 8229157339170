<template>
    <div id="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="box">
						<NotesList />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    export default {
        name: "NoteListe",
        components: {
			NotesList: () => import('GroomyRoot/components/Notes/List')
        }
    }
</script>
